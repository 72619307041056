
function BaseVacancy (value) { // 空白校验
  let regu = '^[ ]+$'
  let re = new RegExp(regu)
  let bol = re.test(value)
  return bol
}

function inputNumber (value) { // text 框校验函数 不能为复数
  if (value[0] != 0) {
    let reg = /^\d+(\.\d{0,2})?$/
    let data = reg.test(value)
    if (!data) {
      return value.length - 1
      // this.$set(this.form, type, value.slice(0, value.length - 1))
    }
  } else if (value.length >= 2 && value[1] != '.') {
    return 1
    // this.$set(this.form, type, value.slice(0, 1))
  }
  return 'next'
}
export { BaseVacancy, inputNumber }
