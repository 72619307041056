/**
 * Created by zzt on 19/1/9.
 * http配置
 */

import axios from "axios";
import cookie from "@/storage/cookies.js";
import store from "@/store/index.js";
import { addDecryptInterceptor } from "zj-interceptors";
import { reject } from "lodash";
var env = require("../../env");
let http = axios.create({
  // timeout: 7000,
  baseURL: env[process.env.environment].VUE_APP_API
});
if (process.client) {
  addDecryptInterceptor(http);
}
http.interceptors.request.use(
  config => {
    let token = cookie.cookieRead("token");
    config.headers.tag = "member"; // 添加标识位，我也不太懂具体authentication/sv1/user这个接口
    // let sign = spiderHack.gernerateStrV1();
    // config.headers.sign = sign;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    let arr = ["/api/search/searchKeywordsCount", "/api/search/findItem"];
    let indexOf = config.url.indexOf("/api/search/searchKeywordsCount");
    let indexOf1 = config.url.indexOf("/api/search/findItem");
    let indexOf2 = config.url.indexOf("/v1/commodity/summary");
    let indexOf3 = config.url.indexOf("/v1/comService/searchComList");
    if (
      config.url == `${process.env.VUE_APP_LOGINAPI}/authentication/sv1/user`
    ) {
      config.headers.tag = "member";
    }
    // let indexOf4 = config.url.indexOf('/ov1/mechanicalLabor/tenderBidderList');
    if (
      indexOf !== -1 ||
      indexOf1 !== -1 ||
      indexOf2 !== -1 ||
      indexOf3 !== -1
    ) {
      if (config.method == "post") {
        config.data = {
          ...config.data
        };
      } else if (config.method == "get") {
        config.params = {
          ...config.params
        };
      }
      return config;
    }

    let cookieArea = cookie.cookieRead("area");
    // let area = cookieArea ? JSON.parse(cookieArea) : null;
    // if (area) {
    //   if (config.method == "post") {
    //     config.data = {
    //       areacode: "",
    //       ...config.data
    //     };
    //   } else if (config.method == "get") {
    //     config.params = {
    //       areacode: "",
    //       ...config.params
    //     };
    //   }
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status == 500) {
      return error.response.data;
    }
    if (error.response && process.client) {
      switch (error.response.status) {
        case 401:
          // console.log(store(), '$$$$$$$$$$$$$$$$$$$$');
          store().commit("LOGIN_OUT_SERVER");
          break;
        case 403:
          store().commit("LOGIN_OUT_SERVER");
          break;
        case 666:
          store().commit("LOGIN_OUT_SERVER");
          break;
        case 490:
          break;
      }
    }
    let reject =
      error && error.response && error.response.data
        ? error.response.data
        : null;
    return Promise.reject(reject);
    // return Promise.resolve(error)
  }
);

export default {
  get(url, params, config = {}) {
    return new Promise((resolve, reject) => {
      http({
        ...config,
        method: "get",
        params,
        url
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  post(url, data, config = {}) {
    return new Promise((resolve, reject) => {
      http({
        method: "post",
        data,
        url,
        headers: config.headers || {}
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  postParams(url, params) {
    return new Promise((resolve, reject) => {
      http({
        method: "post",
        params,
        url
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  postParamsData(url, params, data) {
    return new Promise((resolve, reject) => {
      http({
        method: "post",
        params,
        data,
        url
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  postLogin(url, params, config = {}, withCredentials = false) {
    return new Promise((resolve, reject) => {
      http({
        method: "post",
        headers: {
          Authorization: "Basic Z2N0eDpnY3R4"
        },
        url,
        params,
        headers: config.headers || {},
        withCredentials
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  put(url, data) {
    return new Promise((resolve, reject) => {
      http({
        method: "put",
        data,
        url
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  patch(url, data) {
    return new Promise((resolve, reject) => {
      http({
        method: "patch",
        data,
        url
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  delete(url) {
    return new Promise((resolve, reject) => {
      http({
        method: "delete",
        url
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

