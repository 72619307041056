import moment from 'moment'
/**
     * @description 获取货源装货时间
     * @export
     * @param {Object} goods 货源详情数据
     * @return {String}  timeStr 返回处理后的时间字符串
     */
 export function getShipTimeStr(goods, showYear = true) {
  /*
  装货时间显示要求
  a、装货开始时间和结束时间相等或无装货结束时间，显示XX月XX日XX点装货，如8月3日12点装货。
  b、不同时间时，显示XX月XX日XX点 - XX月XX日XX点装货，如8月3日12点 - 8月4日18点装货。没有设置时间点，显示全天，如8月3日全天 - 8月4日全天装货。
  */

  let timeStr = "";
  let {
    shipmentTime,
    shipmentEndTime,
    startPointTime,
    endPointTime
  } = goods;
  let start = shipmentTime ? formatDate(new Date(shipmentTime)) : "";
  let end = shipmentEndTime ? formatDate(new Date(shipmentEndTime)) : "";
  let startTime = startPointTime
    ? `${start} ${
        startPointTime < 10 ? "0" + startPointTime : startPointTime
      }:00`
    : start;
  let endTime = "";
  if (end) {
    endTime = endPointTime
      ? `${end} ${endPointTime < 10 ? "0" + endPointTime : endPointTime}:00`
      : end;
  } else {
    endTime = "";
  }
  if (start && end) {
    if (startTime == endTime) {
      timeStr = splitStr(formatforMonth1(startTime, showYear)) + "装货";
    } else {
      timeStr =
        splitStr(formatforMonth1(startTime, showYear)) +
        "~" +
        splitStr(formatforMonth1(endTime, showYear)) +
        "装货";
    }
  }
  if (shipmentTime && !shipmentEndTime) {
    timeStr = timeStr =
      splitStr(formatforMonth1(startTime, showYear)) + "装货";
  }
  return timeStr;
}
/**
 * @description 去点时间点地0
 *
 * @param {String} str 要处理地时间字符串
 * @return {String}  newStr 返回处理后地时间字符串
 */
 export function splitStr(str) {
  let newStr = ''
  if (str.split('日')[1] == '0点') {
    newStr = str.split('日')[0]+'日'
  } else {
    newStr = str;
  }
  return newStr;
}
// 运输距离
export function formatDistance(value, unit) {
  let uni = unit === 1 ? '公里' : 'KM'
  let num = Number(value)
  if(num && (num / 1000) > 1) {
    return (num / 1000).toFixed(1) + uni
  } else {
    return '1' + uni + '以内'
  }
}
export function formatforMonth1 (value,showYear) {
  if (!value) {
    return
  }
  let arr = value.split(' ')
  if (arr[1] && arr[1] == '25:00') {
    return moment(arr[0]).format(showYear ? 'M月D日全天' :'YYYY年M月D日全天')
  } else {
    let date = new Date(value)
    let h = date.getHours()
    if (h == 0) {
      return moment(value).format(showYear ? 'M月D日' :'YYYY年M月D日')
    } else {
      return moment(value).format(showYear ? 'M月D日H点' :'MYYYY年月D日H点')
    }
  }
}
export function datanumber2datetime(datetime, fmtdata) {
  function padLeftZero(str) {
    return ("00" + str).substr(str.length);
  }
  if (datetime === "" || datetime === null) {
    return "";
  }
  const date = new Date(datetime);
  let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  const o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + "";
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}

export function formatDate (value) {
  if(!value){
    return ''
  }
  return moment(value).format('YYYY-MM-DD')
}
// 带时分秒
export function formatDateHMs (value) {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
}
// 只有月日的时间
export function formatDateMD (value) {
  return moment(value).format('MM-DD')
}
export function contactSku(list){
  let arr = JSON.parse(JSON.stringify(list))
  let str = ''
  let sortList = sortChinese(arr, 'attribute')
  if(sortList && sortList.length){
    sortList.forEach(item => {
      str += item.name+':'+item.val+ '/'
    })
  }
  str = str.substring(0, str.length-1)
  return str
}

/**
 * 字段排序
 * @param arr 数组
 * @param sortAttr 需要排序的属性值
 * @returns {*}
 */
function sortChinese (arr, sortAttr) {
  function getValue (option) {
    if (!sortAttr) return option
    let data = option
    sortAttr.split('.').filter(function (item) {
      data = data[item]
    })
    return data + ''
  }
  arr.sort(function (item1, item2) {
    return getValue(item1).localeCompare(getValue(item2), 'zh-CN');
  })
  return arr
}